.homepage {
    text-align: center;
    margin-top: 50px; /* Adjust as needed */
    overflow-x: hidden;
}

body {
    background-color: white;
    position: relative;
    overflow-x: hidden;
}

.top-sliding-rectangle {
    position: absolute;
    right: -200px; /* Start off-screen */
    top: 2%; /* Align with the center of the first rectangle */
    width: 200px; /* Thin rectangle width */
    height: 30px; /* Thin rectangle height */
    background-color: rgb(62, 62, 67);
    animation: slideOut 0.7s 9.5s forwards; /* Slide out after 10 seconds */
}

@keyframes slideOut {
    to {
        right: 100%; /* Slide to the left */
        transform: translateX(-5%); /* Ensure it goes completely off-screen */
    }
}

.top-left-rectangle {
    font-family: 'Lexend', sans-serif;
    font-size: xx-large;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    color: white;
    padding: 10px;
    opacity: 0;
    -webkit-animation: fade-in-right 0.7s 11s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards; /* Fade in after the sliding rectangle */
            animation: fade-in-right 0.7s 11s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
}

@-webkit-keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-right {
    0% {
      -webkit-transform: translateX(50px);
              transform: translateX(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

@keyframes fadeInLeft {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.custom-type-animation-cursor::after {
    color: rgb(149, 255, 0);
    content: '|';
    animation: cursor 1.1s infinite step-start;
}

@keyframes cursor {
    16% {
        opacity: 0;
    }
}

.grid-container {
    display: grid;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    grid-template-columns: repeat(5, 1fr); /* Creates three equal columns */
    grid-template-rows: repeat(5, 1fr); /* Creates three equal rows */
}

.fade-out-container {
    -webkit-animation: fade-out-right 0.7s 7.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
            animation: fade-out-right 0.7s 7.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
}



.typing-animation-container {
    z-index: 2;
    font-family:'Lexend', sans-serif;
    font-size: 2.5em;
    color: white;
    position: absolute;
    top: 40%; /* Adjust as needed */
    left: 48%; /* Center horizontally */
    transform: translateY(-50%);
    transform: translateX(-50%); /* Adjust horizontal position */
    grid-column: span 5;
}


.animated-rectangle {
    z-index: 1;
    position: absolute;
    top: 40%; /* Adjust as needed */
    left: 48%; /* Center horizontally */
    transform: translateY(-50%);
    transform: translateX(-50%); /* Adjust horizontal position */
    background-color: rgb(0, 0, 0);
    width: 0;
    height: 0;
    -webkit-animation: growSquare 0.6s 0.6s forwards, growRectangle 1s forwards; /* 3s delay for the second animation */
    animation: growSquare 1s 1s forwards, growRectangle 1s forwards;  /* 3s delay for the second animation */
}

@-webkit-keyframes growSquare {
    0% {
        width: 0;
        height: 0;
    }
    50%, 100% { /* Pauses at 50% for 1 second */
        width: 100px; /* Size of the square */
        height: 100px;
    }
}

@keyframes growSquare {
    0% {
        width: 0;
        height: 0;
    }
    50%, 100% { /* Pauses at 50% for 1 second */
        width: 100px; /* Size of the square */
        height: 100px;
    }
}

@-webkit-keyframes growRectangle {
    0% {
        width: 100px;
        height: 100px;
    }
    100% {
        width: 600px; /* Width of the rectangle */
        height: 100px;
    }
}

@keyframes growRectangle {
    0% {
        width: 100px;
        height: 100px;
    }
    100% {
        width: 600px; /* Width of the rectangle */
        height: 100px;
    }
}


.sliding-rectangle {
    position: absolute;
    left: -700px; /* Start off-screen */
    top: 46%; /* Align with the center of the first rectangle */
    width: 650px; /* Thin rectangle width */
    height: 30px; /* Thin rectangle height */
    background-color: rgb(62, 62, 67);
    animation: slideIn 1s 7s forwards, carryOff 1s 7.5s forwards; /* Slide in and then carry off */
}

@keyframes slideIn {
    to {
        left: 50%; /* Slide to the center */
        transform: translateX(-50%); /* Center it */
    }
}

@keyframes carryOff {
    to {
        left: 100%; /* Carry off to the right */
        transform: translateX(5%); /* Ensure it goes completely off-screen */
    }
}

@-webkit-keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50px);
                transform: translateX(50px);
        opacity: 0;
    }
}
@keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(50px);
                transform: translateX(50px);
        opacity: 0;
    }
}


.custom-cursor {
    clip-path: polygon(71% 44%, 35% 44%, 23% 77%, 0 0);
    left: -700px;
    /*border-radius: 50%;*/
    width: 50px; /* Adjust size as needed */
    height: 50px;
    background-color: rgb(140, 255, 0);
    /*background-image: url('../../assets/normal-cursor.png'); /* Adjust path if needed */;

    pointer-events: none; /* To prevent cursor interaction */
    position: absolute; /* Keep this to ensure proper positioning */
    z-index: 3; /* High z-index to ensure it's on top */
    -webkit-animation: pulsate-bck 0.5s 1.5s ease-in-out both;
            animation: pulsate-bck 0.5s 1.5s ease-in-out both;
}

@keyframes moveCursor {
    0% { top: 40%; left: 10%; }
    100% { top: 40%; left: 40%; }
}

.white-box {
    z-index: 4;
    width: 50px;
    height: 50px;
}



.blink-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    width: 150px;
    border-radius: 0%;
    z-index: 1;
    position: absolute;
    top: 60%; /* Adjust as needed */
    left: 48%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust horizontal position */
    background-color: black;
    opacity: 0;
    animation: fadeIn 2s 1s ease forwards;
}

.blink-2 p {
    padding-right: 20%;
    padding-left: 20%;
    padding: 20%;
    font-size: 2em;;
    margin: 0;
    font-family:'Lexend', sans-serif;
    color: white;
}



/* ----------------------------------------------
 * Generated by Animista on 2023-11-22 1:22:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
 @-webkit-keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }


  @-webkit-keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


  .screen-wipe {
    position: fixed; /* Fixed position to cover the entire screen */
    top: 0;
    left: 0;
    width: 100px; /* Starting width */
    height: 100px; /* Starting height */
    background-color: black; /* Or any color you want for the wipe */
    z-index: 5; /* Above other elements */
    transform: translate(-100%, -100%); /* Start off-screen */
    opacity: 1; /* Start hidden */
    animation: screenWipeAnimation 2s forwards; /* Animation duration */
    animation-delay: 1.9s; /* Delay before the animation starts */
}

@keyframes screenWipeAnimation {
    0% {
        transform: translate(-100%, -100%) scale(1); /* Start from top left */
    }
    50% {
        transform: translate(50%, 50%) scale(30); /* Scale to cover screen */
    }
    100% {
        transform: translate(4000%, 900%) scale(10); /* Move off bottom right */
    }
}

.home-page-container {
    animation: fadeIn 2s ease-out;
    opacity: 0; /* Start with the home page hidden */
}
