

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

body {
    background-color: white;
    position: relative;
    overflow-x: hidden;
    margin: 0;
}


.fade-in-left-container {
	-webkit-animation: fade-in-left 0.8 4s cubic-bezier(0.390, 0.575, 0.565, 1.000)  forwards;
	        animation: fade-in-left 0.8 4s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
}


.header-rectangle {
    position: relative;
    overflow: hidden;
    padding-bottom: 20px;
    overflow-y:hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    width: 102%; /* Thin rectangle width */
    height:max-content; /* Thin rectangle height */
    background-color: rgb(0, 0, 0);
}

.header-name {
    z-index: 4;
    padding-left: 2%;
    color: white;
    font-family: 'Lexend', sans-serif;
    font-weight: bold;
    font-size: 6em;
}

.header-info {
    z-index: 4;
    padding-left: 2.5%;
    color: white;
    font-family: 'Lexend', sans-serif;
    font-size: 1.5em;
}

.socials-bar {
    display: flex;
    flex-wrap: wrap;
    width: 30%;
    justify-items: left;
    justify-content: space-evenly;
    align-content:flex-start;
    z-index: 4;
    padding-top: 1%;
    padding-left: 0.3%;
    color: white;
    font-family: 'Lexend', sans-serif;
    font-size: 1em;
}

.social-link {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center text vertically with the icon */
}

.social-link a {
    text-decoration: none;
    color: white;
}

.social-link span {
    margin-left: 5px;
    margin-right: 20px;
}

.social-link a:hover {
    text-shadow: 0 0 5px #fffafa;
}

.social-link:hover {
    text-shadow: 0 0 5px #fffafa;
}

@media screen and (max-width: 768px) {
    .socials-bar {
        width: 102%; /* Adjust width to fit the screen */
        justify-content: center; /* Center the items */
        margin: 0 auto; /* Center the bar itself */
        padding-top: 30px; /* Adjust padding as needed */
    }

    .header-rectangle {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
    }

    .header-name {
        text-align: center;
        padding-right: 2%;
    }

    .header-info {
        text-align: center;
        padding-right: 2%;

    }

}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-23 20:51:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-3x
 * ----------------------------------------
 */
 @-webkit-keyframes color-change-3x {
    0% {
      background: #19dcea;
    }
    50% {
      background: #b22cff;
    }
    100% {
      background: #ea2222;
    }
  }
  @keyframes color-change-3x {
    0% {
      background: #19dcea;
    }
    50% {
      background: #b22cff;
    }
    100% {
      background: #ea2222;
    }
  }
  

/* ----------------------------------------------
 * Generated by Animista on 2023-11-23 20:26:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
              transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }


  @keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    will-change: scroll-position;
    position: absolute;
    width: inherit;
    height: 500px;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #000000;
    overflow: hidden;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(113, 255, 0, 0.2);
    animation: animate 15s linear infinite;
}


.background li:nth-child(0) {
    left: 33%;
    width: 131px;
    height: 131px;
    bottom: -131px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 63%;
    width: 217px;
    height: 217px;
    bottom: -217px;
    animation-delay: 4s;
}
.background li:nth-child(2) {
    left: 20%;
    width: 164px;
    height: 164px;
    bottom: -164px;
    animation-delay: 9s;
}
.background li:nth-child(3) {
    left: 77%;
    width: 260px;
    height: 260px;
    bottom: -260px;
    animation-delay: 9s;
}
.background li:nth-child(4) {
    left: 2%;
    width: 165px;
    height: 165px;
    bottom: -165px;
    animation-delay: 11s;
}
.background li:nth-child(5) {
    left: 70%;
    width: 207px;
    height: 207px;
    bottom: -207px;
    animation-delay: 6s;
}
.background li:nth-child(6) {
    left: 81%;
    width: 245px;
    height: 245px;
    bottom: -245px;
    animation-delay: 13s;
}
.background li:nth-child(7) {
    left: 32%;
    width: 180px;
    height: 180px;
    bottom: -180px;
    animation-delay: 5s;
}
.background li:nth-child(8) {
    left: 47%;
    width: 171px;
    height: 171px;
    bottom: -171px;
    animation-delay: 30s;
}
.background li:nth-child(9) {
    left: 48%;
    width: 183px;
    height: 183px;
    bottom: -183px;
    animation-delay: 33s;
}
.background li:nth-child(10) {
    left: 51%;
    width: 238px;
    height: 238px;
    bottom: -238px;
    animation-delay: 1s;
}
.background li:nth-child(11) {
    left: 16%;
    width: 241px;
    height: 241px;
    bottom: -241px;
    animation-delay: 40s;
}


.education-section {
    padding-top: 2%;
    position: initial;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: start;
    gap: 20vw; /* Adjust the space between columns */
    width: 100vw;
    background-color: #ffffff;
    height:fit-content;
    padding-bottom: 5%;
}

.education-left {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-start;
    margin-left: 2.3vw;
}

.education-right {
    flex: 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.education-right span {
    color: rgb(0, 0, 0);
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2em;
}

.course-list {
    text-align: left;
    padding: 20px;
    border: 2px solid #000000; /* Solid black border, adjust color and width as needed */
    border-radius: 8px; /* Rounded corners */
    direction: rtl;
    width: 30vw; /* Adjust the width as needed */
    height: 18vh; /* Adjust the height as needed */
    overflow-y: scroll;
}

.course-list ul {
    font-weight: 300;
    font-size: 1.0em; /* Font size for the text */
    font-family: 'Lexend', sans-serif;
    color: rgb(0, 0, 0);
    padding: 5px 10px;
    list-style: none; /* Removes default list styling */
    padding: 0;
    margin: 0;
}

.course-list li {
    margin-bottom: 10px; /* Space between items */
    font-family: 'Lexend', sans-serif;
    font-weight: 200;
}

.course-list::-webkit-scrollbar {
    padding: 50px;
    width: 10px; /* Width of the scrollbar */
}

.course-list::-webkit-scrollbar-track {
    background: transparent; /* Transparent track */
}

.course-list::-webkit-scrollbar-thumb {
    border: 1px solid #000000; /* Solid black border, adjust color and width as needed */
    border-radius: 15px; /* Rounded corners */
    background-color: transparent; /* Color of the scrollbar thumb */
}

.course-list::-webkit-scrollbar-thumb:hover {
    background: #002145; /* Color when hovered */
}

.school-name {
    display: flex;
    flex-direction: row;
    z-index: 4;
    padding-bottom: 2vh;
}

.school-name span {
    color: rgb(0, 0, 0);
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2em;
}

.edu-info-container {
    align-items: center;
    margin-left: 5vw;
    display: flex;
    flex-direction: row;
}

.rounded-rectangle {
    background-color:#002145;
    border-radius: 15px; /* Rounded corners */
    padding: 5px 10px; /* Padding inside the rectangle */
    text-align: center; /* Center the text */
}

.rounded-rectangle-degree {
    background-color:#002145;
    border-radius: 15px; /* Rounded corners */
    padding: 5px 10px; /* Padding inside the rectangle */
    margin: 0vh 1vw; /* Margin around the rectangle */
    text-align: center; /* Center the text */
}

.rounded-rectangle-degree span {
    font-weight: 300;
    font-style: italic;
    font-size: 1.0em; /* Font size for the text */
    font-family: 'Lexend', sans-serif;
    color: rgb(255, 255, 255);
}

.rounded-rectangle span {
    font-weight: 300;
    font-style: italic;
    font-size: 1.0em; /* Font size for the text */
    font-family: 'Lexend', sans-serif;
    color: rgb(255, 255, 255);
}

.education-title {
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
    margin-top: 40px;
    margin-left: 20px;
    z-index: 4;

}

.education-title h1 {
    font-size: 2rem;
    left: 4%;
    top:25%;
    color: rgb(0, 0, 0);
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-size: 2em;
}

.education-section img {
    padding-top: 1%;
    height: 5rem;
    margin-bottom: 1vh;
}

.projects-section {
    padding-top: 2%;
    width: 100vw;
    height: fit-content;
    padding-bottom: 10%;
    background-color: #ffffff;
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 30px; /* Space between cards */
    justify-content:center;

}

.projects-title {
    margin-top: 40px;
    margin-left: 20px;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    z-index: 4;
    color: rgb(0, 0, 0);
    font-family: 'Lexend', sans-serif;
    font-size: 2em;
}

.projects-title h1 {
    font-size: 2rem;
    left: 4%;
    top:25%;
    color: rgb(0, 0, 0);
    font-family: 'Lexend', sans-serif;
    font-style: normal;
}


@media screen and (max-width: 768px) {

    .education-title {
        display: flex;
        align-self: flex-start;
        justify-self: flex-start;
        margin-top: 40px;
        margin-left: 5px;
        z-index: 4;
    
    }


    .education-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10vw;
    }

    .course-list {
        text-align: left;
        padding: 20px;
        border: 2px solid #000000; /* Solid black border, adjust color and width as needed */
        border-radius: 8px; /* Rounded corners */
        direction: rtl;
        width: 90vw; /* Adjust the width as needed */
        height: 14vh; /* Adjust the height as needed */
        overflow-y: scroll;
    }

    .projects-section {
        padding-top: 2%;
        width: 99vw;
        height: fit-content;
        padding-bottom: 30%;
        background-color: #ffffff;
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 30px; /* Space between cards */
        justify-content:center;
    
    }

    .projects-title {
        margin-top: 40px;
        margin-left: 5px;
        align-self: flex-start;
        display: flex;
        flex-direction: row;
        z-index: 4;
        color: rgb(0, 0, 0);
        font-family: 'Lexend', sans-serif;
        font-size: 2em;
    }

}

.blur-background {
    filter: blur(5px);
    transition: filter 1s ease; /* Adjust the duration as needed */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
}

.font-style-1 {
    font-family: 'Outfit', sans-serif;
}

.font-style-2 {
    font-family: 'Vina Sans', sans-serif;
}

.font-style-3 {
    font-family: 'Poppins', sans-serif;
}


.myproject-card {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    font-family: 'Lexend', sans-serif;
    border: 2px solid black; /* Black border */

    background-color: transparent;
    padding: 15px;
    cursor: pointer;
    overflow: hidden; /* Prevents content from overflowing */

}

.myproject-card h2 {
    font-size: 2em;
    text-wrap:wrap;
    margin-bottom: 10px;
}

.myproject-card p { 
    display: flex;
    flex-wrap: wrap;
    padding-top: 1em;
}

.expanded-content {
    display: flex;
    flex-direction: column;
    overflow-wrap:break-word;
}

.card-link-button {
    text-align: center;
    display: inline-block;
    background-color: #333;
    color: white;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.card-link-button:hover {
    background-color: #555;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  *::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  *::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovered */
  }